import React from 'react';
import './App.css';
import Seasons from './components/seasons/seasons.js';
import Months from './components/months/months.js';
import MatchformApi from './components/matchformApi/matchformApi.js';
import Gallery from 'react-image-gallery';

import Img from 'react-fix-image-orientation';

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      season: "",
      images: [],
      baseImages: [],
      months: [],
      selectedImages: []
    };
    this.seasonChanged = this.seasonChanged.bind(this);
    this.monthChanged = this.monthChanged.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.renderThumbInner = this.renderThumbInner.bind(this);
  }

  seasonChanged(season) {

    (new MatchformApi()).listSeasonImages(season).then(results => {
      let imageList = [];
      let monthList = [];
      results.data.reverse().forEach(item => {
        let date = item.substr(0, 10).split("-").reverse().join("/");
        let month = date.substr(3, 2);
        imageList.push({
          original: `https://api.matchforms.codingentity.me.uk/v1/${this.state.season}/full/${item}`,
          originalClass: "originalImage",
          thumbnail: `https://api.matchforms.codingentity.me.uk/v1/${this.state.season}/thumb/${item}`,
          description: date,
          thumbnailLabel: date,
          month: month
        });
        monthList.push(month);
      });
      const months = monthList.filter((val, index, arr) => {
        return arr.indexOf(val) === index
      });


      this.setState({
        baseImages: results.data,
        months: months.reverse(),
        images: imageList
      });
    })
      .catch(err => {
        console.error("failed to load file list", err);
      });

    this.setState({
      season: season
    });
  }

  monthChanged(month) {
    const filteredMonths = this.state.images.filter(x => x.month === month);
    this.setState({
      selectedImages: filteredMonths
    });
  }

  renderItem(item) {

    let onThumbnailError = this.props.onThumbnailError || this._handleImageError;
    return (
      <div className='image-gallery-image'>
        {
          item.description &&
          <span className='image-gallery-description'>
            {item.description}
          </span>
        }
        {
          item.imageSet ?
            <picture
              onLoad={this.props.onImageLoad}
            >
              {
                item.imageSet.map((source, index) => (
                  <source
                    key={index}
                    media={source.media}
                    srcSet={source.srcSet}
                    type={source.type}
                  />
                ))
              }
              <Img
                alt={item.originalAlt}
                src={item.original}
                onError={onThumbnailError}
              />
            </picture>
            :
            <Img
              src={item.original}
              alt={item.originalAlt}
              srcSet={item.srcSet}
              sizes={item.sizes}
              title={item.originalTitle}
              onLoad={this.props.onImageLoad}
              onError={onThumbnailError}
            />
        }


      </div>
    );
  };

  renderThumbInner(item) {
    let onThumbnailError = this.props.onThumbnailError || this._handleImageError;

    return (
      <div className='image-gallery-thumbnail-inner'>
        <Img
          src={item.thumbnail}
          alt={item.thumbnailAlt}
          title={item.thumbnailTitle}
          onError={onThumbnailError}
        />
        {item.thumbnailLabel &&
          <div className='image-gallery-thumbnail-label'>
            {item.thumbnailLabel}
          </div>
        }
      </div>
    );
  };

  render() {
    return (
      <div className="App">
        <header className="App-header">
          OKA Matchforms Gallery
        </header>
        <Seasons onSeasonChanged={this.seasonChanged} />
        <Months onMonthChanged={this.monthChanged} months={this.state.months} />
        <Gallery items={this.state.selectedImages}
          lazyLoad={true}
          showPlayButton={false}
          additionalClass={"app-image-gallery"}
          showFullscreenButton={false}
          showBullets={true}
        //  renderItem={this.renderItem}
        //  renderThumbInner={this.renderThumbInner}
         />
      </div>
    );
  }
}

export default App;
