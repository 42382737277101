import React from 'react';
import './season.css';

class season extends React.Component {
    constructor(props){
        super(props);

        this.loadGallery = this.loadGallery.bind(this);
    }

    loadGallery(e){
        console.log(e.target.innerText);
        this.props.loadGallery(e.target.innerText);
    }

    render() {
      return(<div className={'season' + (this.props.selected ? ' selectedalbum':'')} key={"gallery-"+this.props.season} onClick={this.loadGallery}>{this.props.season}</div>);
    }
}
export default season