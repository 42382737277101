import apigClientFactory from 'aws-api-gateway-client';

class MatchformApi {

    
    constructor(){
        const apiconfig = {invokeUrl: "https://api.matchforms.codingentity.me.uk/v1/"};
        this.awsclient = apigClientFactory.newClient(apiconfig);
        this.getSeasons = this.getSeasons.bind(this);
        this.listSeasonImages = this.listSeasonImages.bind(this);
    }
    
    getSeasons(){
        return this.awsclient.invokeApi({},"season","GET");
    }

    listSeasonImages(season){
        return this.awsclient.invokeApi({season:season},"{season}/list",'GET');
    }
};

export default MatchformApi