import React from 'react';
import './months.css';

import Month from '../season/season.js';

class Months extends React.Component {


    monthLookupTable = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
        "Jan": "01",
        "Feb": "02",
        "Mar": "03",
        "Apr": "04",
        "May": "05",
        "Jun": "06",
        "Jul": "07",
        "Aug": "08",
        "Sep": "09",
        "Oct": "10",
        "Nov": "11",
        "Dec": "12"
    };

    constructor(props) {
        super(props)
        this.state = { season: [], selectedMonth: '' };
        this.loadToGallery = this.loadToGallery.bind(this);
    }

    loadToGallery(month) {
        this.props.onMonthChanged(this.monthLookupTable[month]);
        this.setState({ selectedMonth: month });
    }


    componentDidUpdate(prevProps) {
        if (this.props.months !== prevProps.months) {
            this.setState({selectedMonth:this.monthLookupTable[this.props.months[this.props.months.length-1]]});
            this.props.onMonthChanged(this.props.months[this.props.months.length-1]);
        }
      }


    render() {

        const renderedMonths = this.props.months.map((monthIdx) => {
            const monthTxt = this.monthLookupTable[monthIdx];
            return <Month season={monthTxt} loadGallery={this.loadToGallery} key={"monthTxt-" + monthTxt} selected={monthTxt === this.state.selectedMonth} />
        }).reduce((acc, x) => acc === null ? [x] : [acc, <div key={"season-divider" + x} className="seasondivider">|</div>, x], null);
        return (
            <div className="month">
                {renderedMonths}
            </div>
        );
    }
}

export default Months