import React from 'react';
import './seasons.css';

import Season from '../season/season.js';
import MatchformApi from '../matchformApi/matchformApi.js';

class Seasons extends React.Component {

    constructor(props){
        super(props)
        this.state = {seasons: [],selectedSeason:''};
        
        (new MatchformApi()).getSeasons().then(res => {
            this.setState({    
                seasons: res.data,
                selectedSeason: res.data[res.data.length -1]
            });
            this.props.onSeasonChanged(res.data[res.data.length-1]);
        })
        .catch(err => {
            console.error(JSON.stringify(err));
        });

        this.loadToGallery = this.loadToGallery.bind(this);
    }

    loadToGallery(season){
        this.props.onSeasonChanged(season);
        this.setState({selectedSeason:season});
    }

    render(){
        const renderedSeasons = this.state.seasons.map((season)=>
            <Season season={season} loadGallery={this.loadToGallery} key={"season-"+season} selected={season===this.state.selectedSeason}/>
        ).reduce((acc,x) => acc === null ? [x] : [acc, <div key={"season-divider"+x} className="seasondivider">|</div>, x], null);
        return (
            <div className="seasons" >
            {renderedSeasons} 
            </div>
        );
    }
}

export default Seasons